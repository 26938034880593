import * as React from "react"
import ContentLoader from "react-content-loader"
import * as styles from "./LoadingTopButton.module.css"

const LoadingTopButton = () => {
  return (
    <>
      <div className={styles.container}>
        <ContentLoader viewBox="0 0 100 20" backgroundColor="#f5f5f5" foregroundColor="#dfdfdf">
          <rect x="0" y="0" rx="4" ry="4" width="100%" height="20" />
        </ContentLoader>
      </div>
      <div className={styles.containerMobile}>
        <ContentLoader viewBox="0 0 200 20" backgroundColor="#f5f5f5" foregroundColor="#dfdfdf">
          <rect x="0" y="0" rx="4" ry="4" width="100%" height="20" />
        </ContentLoader>
      </div>

    </>
  )
}

export default LoadingTopButton
